/*
 * This file only contains styling for items used globally
*/
@import 'variables';
@import 'mixins';

body {
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: none !important;
}

/*
 TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
mat-card.main-card,
.main-card {
  padding: $default_page_padding_top;
}

/*
 TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
mat-card.side-card,
.side-card {
  padding: $default_page_padding_top $default_padding_between_components;
}

a,
a:link,
a:visited {
  color: $primary;
  text-decoration: underline;

  &.active {
    color: $primary;
  }
}

.button-container {
  :not(.mat-mdc-cell) > & {
    margin-top: $default_padding_between_components;
  }

  &.mat-mdc-dialog-actions {
    margin-bottom: -($default_padding_between_components);
  }

  .mat-mdc-button-base + .mat-button-base {
    margin-left: $default_padding_between_components;
  }
}

.required-fields-container {
  margin-top: $default_padding_between_components * 2;
}

.info {
  background: $info;
}

.success {
  background: $success;
  color: white;
}

.danger {
  background: $danger;
  color:white;
}

.warning {
  background: $warning;
  color:white;
}

.error {
  background: $danger;
  color: white;
}

.highlighted {
  background-color: $highlighted;
}

.inactive {
  color: $warning;
}

.refused {
  color: $danger;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.pointer {
  cursor: pointer;
}

.dialog-fullscreen-on-mobile {
  @include respond-to('small') {
    width: 100vw;
    max-width: 100vw !important;
    height: 100vh;
    .mat-horizontal-content-container {
      padding: 0px;
    }
    .mat-horizontal-stepper-header {
      padding: 0px;
    }
    .mat-toolbar {
      h1 {
        line-height: 42px;
      }
    }
  }
}

hr.dotted {
  border: 0;
  border-top: 2px dotted $accent;
  margin-top: $default_padding_between_components;
  margin-bottom: $default_padding_between_components;
}

hr.soft {
  border: 0;
  border-top: 2px solid $base-line-color;
  margin-top: $default_padding_between_components;
  margin-bottom: $default_padding_between_components;
}

.no-margin {
  margin: 0 !important;
}

.secondary-action {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 11px;
  color: $gray-text;
}

@media screen and (max-width: 600px) {
  .hide-on-mobile {
    visibility: hidden;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

// Beamer
#beamerAnnouncementBar {
  z-index: 1;
}
