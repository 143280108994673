/*
 * This file only contains the variables used
*/
@use '@angular/material' as mat;
@use 'sass:math';
@import 'palette';
@import 'theme';

$primary: mat.m2-get-color-from-palette($custom-theme-primary);
$accent: mat.m2-get-color-from-palette($custom-theme-accent);
$warn: mat.m2-get-color-from-palette($custom-theme-warn);

// Setup dimensions
$page-left-padding: 10% !default;
$page-right-padding: $page-left-padding;

$default_padding_between_components: 16px;
$large_padding_between_components: 2 * $default_padding_between_components;
$medium_padding_between_components: math.div(
  $default_padding_between_components,
  2
);
$small_padding_between_components: math.div(
  $default_padding_between_components,
  4
);

$default-icon-padding: 6px;
$disabled-icon-color: rgba(0, 0, 0, 0.26);
$disabled-icon-border-color: rgba(0, 0, 0, 0.12);

$default_page_padding_bottom: 120px;
$default_page_padding_top: 40px;

// Other
$spinner-overlay-background-color: rgba(255, 255, 255, 0.8);
$base-font-color: map-get($iam-blue, 500);
$base-line-color: map-get($iam-grey, 500);

$highlighted: map-get($md-context-gray-portbase, 50);
$highlighted-contrast: mat.m2-get-contrast-color-from-palette(
  $md-context-gray-portbase,
  50
);

$gray-text: map-get($md-context-gray-portbase, 900);

$portbase-orange: #ec6726;

// Base notification colors
$info: #33b5e5;
$info-bg: #E0F0FE;
$info-text: #004478;
$info-border: #94C1EB;
$warning: #fb3;
$warning-bg: #FEF6E1;
$warning-text: #6C5606;
$warning-border: #EBD294;
$danger: #ff3547;
$danger-bg: #FEE1E1;
$danger-text: #6C0606;
$danger-border: #EB9494;
$success: #00c851;
$success-bg: #E9FEE1;
$success-text: #226C06;
$success-border: #B0DF9F;
$ok-button: #39a966;
$cancel-button: #ec4545;
$primary-button: #004478;

$breakpoints: (
  'small': 600px,
  'medium': 960px,
  'large': 1280px,
  'xlarge': 1920px,
) !default;
