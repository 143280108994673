/*
 * This file only contains Material overrides
*/
@import 'variables';
@import 'mixins';

.mat-typography {
  h4 {
    font-weight: $font-weight-bold;
    margin-bottom: 0.2em;
  }
}

.mat-button-wrapper {
  .mat-divider-vertical {
    display: inline-block;
  }
}

mat-card-content.mat-mdc-card-content {
  padding: 0;

  &:last-child {
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }
}

header,
iam-content {
  .mat-mdc-button-base {
    letter-spacing: normal;
  }
}

iam-content {
  .mat-mdc-button-base {
    .mat-button-wrapper {
      white-space: normal;
    }
  }
  .mat-mdc-outlined-button {
    &.icon-button {
      min-width: inherit;
      padding: $default-icon-padding;

      .mat-button-wrapper {
        display: flex;
        align-items: center;
      }
      &[disabled] {
        border-color: $disabled-icon-border-color;
        .st0 {
          fill: $disabled-icon-color !important;
        }
      }
    }
  }
}

.mat-mdc-card {
  .mat-card-header-text {
    margin-left: 0;
    margin-right: 0;
  }
}

.mat-mdc-cell,
.mat-expansion-panel,
.mat-expansion-panel-header-title,
.mat-mdc-card,
.mat-mdc-unelevated-button {
  color: inherit;
}

pdc-dialog {
  .mat-mdc-dialog-content,
  .mat-mdc-dialog-actions,
  .mat-mdc-dialog-title,
  .mat-option-text {
    color: $base-font-color;
  }

  h1 {
    &.mat-mdc-dialog-title {
      font-size: $font-size-h2;
      font-weight: $font-weight-bold;
      line-height: $line-height-h2;
    }
  }
}

.required-fields-dialog,
.wizard-dialog,
.change-business-information-dialog,
.open-request-details-dialog {
  font-family: $font-family-base;
  color: $base-font-color;

  .mat-mdc-dialog-content,
  .mat-mdc-dialog-actions,
  .mat-mdc-dialog-title,
  .mat-option-text {
    font-family: $font-family-base;
    color: $base-font-color;
  }

  h1 {
    &.mat-mdc-dialog-title {
      font-size: $font-size-h2;
      font-weight: $font-weight-bold;
      line-height: $line-height-h2;
    }
  }
}

.mat-mdc-tab-body {
  padding-top: $large_padding_between_components;
}

.mat-mdc-snack-bar-container {
  background: transparent !important;
  border-radius: 0;

  .mdc-snackbar__surface {
    display: flex;
    flex-direction: row;
    min-width: calc(((100vw / 12) * 11) - 60px) !important;
    max-width: calc(((100vw / 12) * 11) - 60px) !important;
    margin-bottom: 0;

    border: 1px solid transparent;
    padding: 0;

    & > div {
      width: 100%;
    }

    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.info {
    .mdc-snackbar__surface {
      @apply bg-pb-blue-100 border-pb-blue-300;
      
      * {
        @apply text-pb-blue-700;
      }
    }
  }

  &.warning {
    .mdc-snackbar__surface {
      @apply bg-pb-yellow-100 border-pb-yellow-300;

      * {
        @apply text-pb-yellow-700;
      }
    }
  }
  &.success {
    .mdc-snackbar__surface {
      @apply bg-pb-green-100 border-pb-green-300;

      * {
        @apply text-pb-green-700;
      }
    }
  }
  &.danger,
  &.error {
    .mdc-snackbar__surface {
      @apply bg-pb-red-100 border-pb-red-300;

      * {
        @apply text-pb-red-700;
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      min-width: calc(((100vw / 12) * 9) - 100px) !important;
      max-width: calc(((100vw / 12) * 9) - 100px) !important;
    }
  }
}

mat-expansion-panel {
  box-shadow: none !important;
  border: 1px solid $base-line-color;
}

.mat-mdc-form-field {
  /* Material boxing overrides */
  --mat-form-field-container-height: 48px;
  --mdc-icon-button-state-layer-size: 24px;

  /* Material BG overrides*/
  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-hover-state-layer-opacity: 0;
  --mat-form-field-focus-state-layer-opacity: 0;
  --mdc-filled-text-field-disabled-container-color: transparent;

  /* Material Text overrides */
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-line-height: 16px;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-line-height: 16px;
  --mdc-filled-text-field-input-text-color: currentColor;
  --mdc-filled-text-field-label-text-tracking: 0;
  --mat-form-field-subscript-text-size: 75%;

  .mat-mdc-form-field-hint {
    margin-bottom: 13px;
  }

  .mat-mdc-text-field-wrapper {
    padding-left: 0 !important;

    .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      top: 31px; // Label position on unfilled input
    }
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
    position: relative;
  }

  .mat-mdc-form-field-bottom-align{
    display: flex;
  }

  .mat-mdc-form-field-error.mat-mdc-form-field-bottom-align {
    height: 14px !important;

    &::before {
      display: none;
    }
  }

  .mat-mdc-form-field-hint {
    color: #0000008a;
  }

  // Fix blurry label on Chrome
  .mat-mdc-floating-label.mdc-floating-label,
  .mat-mdc-floating-label.mdc-floating-label mat-label,
  mat-form-field .mdc-text-field {
    will-change: unset;
  }

  .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker) {
    margin-left: 2px;
  }

  .mdc-text-field--focused .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker) {
    color: $danger;
  }

  .mat-mdc-form-field-icon-affix,
  .mat-mdc-form-field-icon-suffix {
    .mat-mdc-icon-button {
      width: 24px;
      height: 24px;
      padding: 0;
      min-width: auto;

      .mat-mdc-button-touch-target {
        width: 24px;
        height: 24px;
      }

      i {
        font-size: 14px;
      }
    }
  }
}

.portbase-datepicker {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.5em 0 0.25em 0;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-form-field-label {
    color: rgba(0, 0, 0, 0.54);
  }
}

// Fix for show scrollbar in mat-select
.mat-mdc-select-panel {
  min-width: 100% !important;
}

// Ugly fix for tabs
.mat-mdc-tab {
  letter-spacing: normal !important;
}
.mat-mdc-tab-link {
  letter-spacing: normal !important;
}
.mat-mdc-cell {
  font-family: $font-family-base !important;
  color: $base-font-color !important;
}
.mat-mdc-tab-header {
  border-bottom-width: 1px !important;
}

.mat-step-header:hover:not([aria-disabled]), 
.mat-step-header:hover[aria-disabled=false] {
  background-color: transparent;
}

.organization-selector{
  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding: 10px 8px 8px 8px !important;
    font-family: $font-family-base !important;
    color: $base-font-color !important;
  }
}

.section-company-info, .section-establishment, .section-contact-details, .select-component{
  .mat-mdc-form-field-infix{
    padding-bottom: 0px !important;
    padding-top: 22px !important;
  }
  .mat-mdc-select-min-line{
    color: black;
    font-size: 16px;
  }
}

.language-selector{
  font-size: 12px !important;
  .mat-mdc-text-field-wrapper{
    height: 28px;
    margin-top: 15px;
  }
  .mat-mdc-form-field{
    margin-top: 16px;
  }
  .mat-mdc-form-field-infix{
    width: auto;
    min-width: 120px;
    padding: 4px 0 4px 0 !important;
  }
  .mat-mdc-select-value-text{
    margin-right: 8px;
  }
}

.mdc-list-item__primary-text {
  margin-right: 0px !important;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button {
  letter-spacing: 0px !important;
}

:root {
  --mat-select-trigger-text-size: 12px;
  --mat-select-enabled-trigger-text-color: $primary;
  --mat-option-label-text-size: 14px;
  --mdc-icon-button-icon-size: 14px;
  --mdc-list-list-item-label-text-size: 16px;
}
