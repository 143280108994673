@use 'sass:map';

@tailwind base;
@tailwind components;
@tailwind utilities;

$colors: (
  'grey-100': #f7fafc,
  'grey-200': #eaf0f4,
  'grey-300': #d6e0e7,
  'grey-400': #b7c5ce,
  'grey-500': #8a9da8,
  'grey-600': #566a76,
  'grey-700': #3d5361,
  'grey-800': #243642,
  'grey-900': #151e24,

  'blue-100': #d7e5fb,
  'blue-200': #acc9f5,
  'blue-300': #81adef,
  'blue-400': #5691e9,
  'blue-500': #2b75e3,
  'blue-600': #0059dd,
  'blue-700': #004ab1,
  'blue-800': #003b84,
  'blue-850': #002357,
  'blue-900': #001b42,

  'green-100': #dffed5,
  'green-200': #cbf5bc,
  'green-300': #b4ef9f,
  'green-400': #90d776,
  'green-500': #76d454,
  'green-600': #4ebf25,
  'green-700': #32a905,
  'green-800': #226c06,
  'green-900': #154404,

  'yellow-100': #fff6d1,
  'yellow-200': #ffe88a,
  'yellow-300': #ffe062,
  'yellow-400': #ffda42,
  'yellow-500': #ffd422,
  'yellow-600': #f7c702,
  'yellow-700': #edb403,
  'yellow-800': #997500,
  'yellow-900': #5b4701,

  'red-100': #fde0e0,
  'red-200': #fbc0c0,
  'red-300': #f89595,
  'red-400': #f45252,
  'red-500': #f23636,
  'red-600': #ee0000,
  'red-700': #c40000,
  'red-800': #930000,
  'red-900': #5c0000,
);

/**
 * NGPrime Data table
 */
.p-datatable {
  @apply text-pb-grey-700;

  .p-datatable-thead {
    @apply text-pb-grey-700 text-lg font-medium;

    th {
      @apply font-medium;
      text-align: left;
      height: 4rem;
    }
  }

  .p-datatable-tbody {
    tr {
      height: 3rem;

      td:first-child {
        padding-left: 8px;
      }
      td:last-child {
        padding-right: 8px;
      }

      &.enable-hover {
        &:hover {
          @apply bg-pb-grey-200;

          td:first-child {
            border-left-style: solid;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
          td:last-child {
            border-right-style: solid;
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
          }
        }
      }
    }
  }
}

/**
 * NGPrime Input field
 */
.p-inputtext {
  @apply text-pb-grey-600;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  background: #fff;
  @apply border-pb-grey-400;
  @apply border;
  border-radius: 2px;
  box-sizing: border-box;
}

.p-inputtext:enabled:hover {
  @apply border-pb-grey-500;
}

.p-inputtext:enabled:active {
  @apply border-pb-blue-700;
}

.p-inputtext:disabled {
  @apply bg-pb-grey-200;
}

.p-inputtext:focus {
  outline: 0 none;
  box-shadow: 0 0 5px 1px;
  @apply shadow-pb-blue-600;
}

.p-input-icon-right {
  position: relative;

  .mat-icon {
    position: absolute;
    right: 8px;
    top: -4px;
  }

  i {
    position: absolute;
    right: 8px;
    top: 3px;
  }
}

.p-inputtext.ng-touched.ng-invalid {
  @apply border-pb-red-700;
}

p-fieldset {
  @apply block mb-3;
}

.p-fieldset-content {
  @apply flex flex-col;
}

.p-label {
  @apply text-pb-grey-800 text-sm leading-4 mb-2;
}

.p-error {
  @apply flex flex-row gap-1 mb-1;

  .rounded-icon {
    @apply w-3 h-3;
  }

  i {
    @apply rounded-full w-3 h-3 p-[3px] bg-pb-red-700;
    @apply text-white;
  }
}

.p-error-text {
  @apply text-pb-red-700 text-xs leading-4;
}

/**
 * NGPrime Select button
 */
.p-buttonset .p-button:not(:last-child) {
  border-right: 1px solid;
}
.p-selectbutton {
  .p-button {
    background: white;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    @apply border;
    @apply border-pb-grey-400;
    @apply text-pb-grey-700;

    @apply shadow-sm;

    &:not(.p-disabled):not(.p-highlight):hover {
      @apply bg-pb-grey-100;
    }

    &.p-highlight {
      @apply bg-pb-blue-100;
      @apply border;
      @apply border-pb-blue-500;
      @apply text-pb-blue-500;
      @apply font-bold;
    }
  }
}

/**
 * NGPrime Button
 */
// Base button (Solid)
.p-button {
  padding: 0.625rem 1rem;
  @apply bg-pb-blue-700 text-white;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03);

  &:enabled {
    &:hover {
      @apply bg-pb-blue-800;
    }
    &:active {
      @apply bg-pb-blue-900;
    }
  }

  &:disabled {
    @apply bg-pb-grey-300 text-pb-grey-500;
  }

  &:focus {
    border-color: #fff;
    outline: 0 none;
    outline-offset: 10px;
    box-shadow: 0 0 5px 1px;
    @apply shadow-pb-blue-600;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(.p-button-text) {
    &.p-button-warning {
      @apply bg-pb-orange-800 text-white;

      &:focus,
      &:hover {
        @apply bg-pb-orange-800 text-white;
      }

      &:disabled {
        @apply bg-pb-grey-300 text-black;
      }
    }

    &.p-button-success {
      @apply bg-pb-green-700 text-white;

      &:enabled {
        &:hover {
          @apply bg-pb-green-800;
        }

        &:focus {
          @apply shadow-pb-green-700 border-white bg-pb-green-700;
        }

        &:active {
          @apply bg-pb-green-700;
        }
      }

      &:disabled {
        @apply bg-pb-green-300;
      }
    }

    &.p-button-danger {
      @apply bg-pb-red-700 text-white;

      &:enabled {
        &:hover {
          @apply bg-pb-red-800;
        }

        &:focus {
          @apply shadow-pb-red-700 border-white bg-pb-red-700;
        }

        &:active {
          @apply bg-pb-red-700;
        }
      }

      &:disabled {
        @apply bg-pb-red-300;
      }
    }

    &.p-button-secondary {
      @apply bg-pb-blue-600 text-white;

      &:focus,
      &:hover {
        @apply bg-pb-blue-700;
      }

      &:disabled {
        @apply bg-pb-blue-300 text-white;
      }
    }

    &.p-button-info {
      @apply bg-pb-grey-800 text-white;

      &:focus,
      &:hover {
        @apply bg-pb-grey-900;
      }

      &:disabled {
        @apply bg-pb-grey-300 text-black;
      }
    }
  }
}

// Raised button
.p-button {
  &.p-button-raised {
    box-shadow:
      0 3px 1px -2px #0003,
      0 2px 2px #00000024,
      0 1px 5px #0000001f;
  }
}

// Icon only
.p-button {
  &.p-button-icon-only {
    span {
      @apply mr-0;
    }
  }
}

// Button sizes
.p-button {
  &.p-button-sm {
    padding: 0.375rem;
    font-size: 1rem;
    line-height: 1rem;
  }

  &.p-button-lg {
    padding: 0.813rem 2.5rem;
    font-size: 1rem;
    line-height: 1.125rem;
  }
}

// Text button
.p-button {
  &.p-button-text {
    /* Default Primary style*/
    @apply text-pb-blue-700;
    background-color: transparent;
    box-shadow: 0;

    &:enabled {
      &:hover {
        @apply bg-pb-blue-100;
      }

      &:active {
        @apply bg-pb-blue-200;
      }
    }

    &:disabled {
      @apply text-pb-blue-300;
    }

    &:focus {
      @apply border-pb-grey-300;
    }

    &.p-button-danger {
      @apply text-pb-red-700;

      &:enabled {
        &:hover {
          @apply bg-pb-red-100;
        }

        &:active {
          @apply bg-pb-red-200;
        }
      }

      &:disabled {
        @apply text-pb-red-200;
      }

      &:focus {
        @apply border-pb-grey-300;
      }
    }

    &.p-button-secondary {
      @apply text-pb-blue-700;

      &:enabled {
        &:hover {
          @apply bg-pb-blue-100;
        }

        &:active {
          @apply bg-pb-blue-200;
        }
      }

      &:disabled {
        @apply text-pb-blue-300;
      }
    }

    &.p-button-warning {
      @apply text-pb-orange-800;

      &:enabled {
        &:hover {
          @apply bg-pb-grey-100;
        }

        &:active {
          @apply bg-pb-grey-200;
        }
      }

      &:disabled {
        @apply text-pb-grey-300;
      }
    }

    &.p-button-info {
      @apply text-pb-grey-800;

      &:enabled {
        &:hover {
          @apply bg-pb-grey-100;
        }

        &:active {
          @apply bg-pb-grey-200;
        }
      }

      &:disabled {
        @apply text-pb-grey-300;
      }
    }
  }
}

// Outlined button
.p-button {
  &.p-button-outlined {
    background: white;
    @apply border-pb-blue-200;
    @apply text-pb-blue-700;

    &:disabled {
      @apply border-pb-grey-300;
    }

    &:hover {
      background: white;
    }

    &:focus {
      @apply border-pb-grey-300;
    }

    &.p-button-danger {
      @apply border-pb-red-200;
    }

    &.p-button-warning {
      @apply border-pb-orange-800;
    }

    &.p-button-secondary {
      @apply border-pb-blue-200;
    }

    &.p-button-info {
      @apply border-pb-grey-400;
    }
  }
}

.p-button:has(.p-button-icon),
.p-button:has(.ng-fa-icon) {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.p-button-icon,
.p-button .ng-fa-icon svg {
  font-size: 1rem;
}

.p-button-icon,
.p-button .ng-fa-icon {
  margin-right: 0.5rem;
}

.p-button-icon.p-button-icon-right,
p-button[iconPos='right'] .ng-fa-icon,
.p-button[iconPos='right'] .ng-fa-icon {
  order: 1;
  margin-right: 0;
  margin-left: 0.5rem;
}

/**
 * NGPrime Sidebar
 */

.p-sidebar-header {
  @apply w-full pl-16 pr-4 py-4 border-b bg-white border-pb-grey-300 flex flex-row justify-between items-center;
}

.p-sidebar-content {
  @apply py-8 px-12 bg-pb-grey-100 text-pb-grey-800;
}

.iam-sidebar-block {
  @apply mb-8 p-6 border border-pb-grey-300 bg-white;
}

.iam-sidebar-block--flex {
  @apply flex flex-row flex-wrap align-top justify-between;
}

p-sidebar .iam-sidebar-block-label {
  @apply leading-none text-[14px] font-normal mt-0 mb-2;
}

p-sidebar .iam-sidebar-block-field {
  @apply leading-none text-[14px] font-medium m-0 break-all;
}

.p-sidebar-close {
  @apply absolute left-4;
}

.iam-sidebar-block-col {
  @apply w-[calc(50%-1.5rem)];
}

.p-sidebar-close svg {
  @apply w-8 h-8 p-2;
}

.iam-sidebar-mask {
  @apply fixed w-full h-full top-0 left-0 bg-black opacity-50 pointer;
}

/**
 * NGPrime Dropdown
 */
.p-dropdown {
  display: flex;
  cursor: pointer;
  position: relative;
  user-select: none;
  @apply border border-pb-grey-400 px-4 py-2 h-10;
}

.p-dropdown.p-disabled,
.p-dropdown.p-disabled .p-inputtext {
  @apply bg-pb-grey-200;
}

.p-dropdown .p-inputtext {
  @apply border-0 h-auto;
}

.p-dropdown-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  @apply ml-2;
}

.p-dropdown-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 3px 0;
}

.iam-sidebar-block .p-dropdown-label {
  @apply py-0;
}

.p-dropdown-label-empty {
  overflow: hidden;
  visibility: hidden;
}

input.p-dropdown-label {
  cursor: default;
}

.p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  @apply py-1.5 px-4 hover:bg-pb-grey-200 focus:bg-pb-grey-300;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @apply border border-pb-grey-400 bg-white rounded-sm;
}

.p-dropdown-filter {
  width: 100%;
}

.p-dropdown-filter-container {
  position: relative;
}

.p-dropdown-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
  width: 1%;
}

.p-dropdown-trigger-icon {
  @apply text-pb-blue-700 w-3 h-3;
}

.p-dropdown-label.p-inputtext {
  @apply text-pb-grey-800;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**
 * NGPrime Calendar
 */
.p-calendar {
  position: relative;
  display: inline-flex;
  max-width: 100%;

  @apply pl-0 pr-0 h-10;
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
  @apply border-none p-0 pl-4 h-auto text-pb-grey-800;
}

/* Fluid */
.p-fluid .p-calendar {
  display: flex;
}

.p-fluid .p-calendar .p-inputtext {
  width: 1%;
}

/* Datepicker */
.p-calendar .p-datepicker {
  @apply min-w-0;
}

.p-datepicker {
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  @apply bg-white shadow-lg;
}

.p-datepicker-inline {
  display: inline-block;
  position: static;
  overflow-x: auto;
}

/* Header */
.p-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @apply pt-4 pb-2 pr-2 pl-4;
}

.p-datepicker-header .p-datepicker-title {
  @apply order-1 flex-1 m-0 font-medium text-pb-grey-800;
}

.p-datepicker-header .p-datepicker-title .p-datepicker-month {
  @apply pr-2;
}

.p-datepicker-prev,
.p-datepicker-next {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  @apply w-8 h-8;
}

.p-calendar .p-icon-wrapper {
  @apply inline-flex items-center justify-center;
}

.p-datepicker-next {
  @apply order-3;
}

.p-datepicker-prev {
  @apply order-2;
}

.p-datepicker-calendar thead {
  @apply border-b mb-2 border-pb-grey-200;
}

.p-datepicker-calendar thead th {
  @apply font-normal text-xs text-pb-grey-500;
}

.p-datepicker-calendar tbody:before {
  @apply content-['\200C'] leading-[0.5rem] block;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

.p-datepicker-multiple-month .p-datepicker-group-container .p-datepicker-group {
  flex: 1 1 auto;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

/* DatePicker Table */
.p-datepicker table {
  width: 100%;
  border-collapse: collapse;
}

.p-datepicker td > span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

/* Month Picker */
.p-monthpicker-month {
  width: 33.3%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  @apply py-2;
}

/*  Button Bar */
.p-datepicker-buttonbar {
  display: flex;
  align-items: center;

  @apply w-full flex-row-reverse justify-start gap-2 py-2 px-2;
}

/* Trigger */
.p-calendar .p-datepicker-trigger {
  @apply bg-white text-pb-blue-800 shadow-none p-0 mt-1 mr-2 ml-2 rounded-full w-8 h-8 enabled:hover:bg-pb-grey-200;
}

.p-calendar-w-btn {
  @apply border w-full border-pb-grey-400;
}

.p-calendar-w-btn .p-inputtext {
  @apply rounded-none focus:shadow-none;
}

.p-datepicker .p-datepicker-calendar-container {
  @apply px-2;
}

.p-datepicker .p-datepicker-calendar-container .p-element {
  @apply w-8 h-8 rounded-full hover:bg-pb-grey-200;
}

.p-datepicker .p-element.p-disabled {
  @apply text-pb-grey-400;
}

.p-datepicker .p-element.p-highlight {
  @apply bg-pb-grey-300;
}

/* Time Picker */
.p-timepicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-timepicker button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.p-timepicker > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
  transform: translate(-50%, -50%);
}

/* Year Picker */
.p-yearpicker-year {
  width: 33%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  @apply py-2;
}

.p-calendar-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  cursor: pointer;
}

.p-calendar-clearable {
  position: relative;
}

p-tableHeaderCheckbox.stretch .p-checkbox,
p-tablecheckbox.stretch .p-checkbox {
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid;
  @apply border-pb-grey-400;
  border-radius: 2px;
  background: #fff;
  width: 16px;
  height: 16px;
  transition:
    background-color 0.2s,
    border-color 0.2s,
    color 0.2s,
    background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #fff;
  font-size: 10px;
}

.p-checkbox .p-checkbox-box .p-icon {
  width: 10px;
  height: 10px;

  margin-left: 2px;
  margin-top: 2px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  @apply bg-pb-blue-700;
  border-radius: 2px;
  color: #fff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  box-shadow: 0 0 0 20px #e0f0fe;
  clip-path: circle(90%);
}

.p-checkbox .p-checkbox-box.p-disabled {
  border-radius: 2px;
  @apply border-pb-grey-400;
  @apply bg-pb-grey-300;
}

.p-checkbox-hide-icon-when-disabled .p-checkbox .p-checkbox-box.p-disabled .p-icon-wrapper {
  display: none;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #b00020;
}

// Dialog
.p-dialog-mask {
  background: rgba(0, 0, 0, 0.4); /* black background with 30% opacity */
}
.p-dialog {
  border-radius: 4px;
  box-shadow:
    0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: white;
  @apply text-pb-blue-700;
  padding: 2rem 2.5rem 1rem 2.5rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: rgba(255, 255, 255, 0.6);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: white;
  @apply text-pb-grey-700;
  padding: 0 2.5rem 2.5rem 2.5rem;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #262626;
  color: rgba(255, 255, 255, 0.87);
  padding: 1rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

// fileUploader
.p-fileupload {
  @apply bg-pb-grey-100;
  @apply border border-pb-grey-300;
  @apply text-pb-grey-600;
}

.p-fileupload-buttonbar {
  @apply p-4;
  @apply border-b border-pb-grey-600;
  .p-element {
    @apply mr-2;
    .fa-upload {
      @apply mr-1;
    }
  }
}

// Message
.p-message {
  @apply border rounded;
}
.p-message .p-message-wrapper {
  padding: 1.25rem 1.5rem;
}
.p-message .p-message-close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.5);
}
.p-message .p-message-close:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-message.p-message-info {
  @apply bg-pb-blue-100 text-pb-blue-700 border-pb-blue-300;
}

.p-message.p-message-success {
  @apply bg-pb-green-100 text-pb-green-700 border-pb-green-300;
}

.p-message.p-message-warn {
  @apply bg-pb-yellow-100 text-pb-yellow-700 border-pb-yellow-300;
}

.p-message.p-message-error {
  @apply bg-pb-red-100 text-pb-red-700 border-pb-red-300;
}

.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

// Tooltip
.p-tooltip {
  @apply absolute;

  .p-tooltip-text {
    @apply bg-pb-grey-800 text-white p-4 rounded text-xs;
  }
}

// Radio button
.p-radiobutton {

  .p-radiobutton-box {
    border: 1px solid map.get($colors, 'grey-400');
    width: 1rem;
    height: 1rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 50%;

    &:hover {
      box-shadow: 0 0 0 32px map.get($colors, 'blue-100');
      background: map.get($colors, 'blue-100');
      clip-path: circle(100%);
    }

    &:active {
      box-shadow: 0 0 0 32px map.get($colors, 'blue-200');
      background: map.get($colors, 'blue-200');
      clip-path: circle(100%);
    }

    &:focus::before {
      content: '';
      position: absolute;
      box-shadow: 0 0 0.25rem 1px map.get($colors, 'blue-500');
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
    }

    &.p-highlight {
      border: 1.5px solid map.get($colors, 'blue-700');
      background: map.get($colors, 'grey-100');
      padding: 3px;

      .p-radiobutton-icon {
        width: 0.5rem;
        height: 0.5rem;
        transition-duration: 0.2s;
        background-color: map.get($colors, 'blue-700');
        border-radius: 50%;
        display: flex;
      }

      &.p-disabled {
        border: 1.5px solid map.get($colors, 'blue-300');

        .p-radiobutton-icon {
          background-color: map.get($colors, 'blue-300');
        }
      }

      &:hover {
        background: map.get($colors, 'blue-100');
      }

      &:active {
        background: map.get($colors, 'blue-200');
      }
    }

    &.p-disabled {
      border: 1px solid map.get($colors, 'grey-400');
      background: map.get($colors, 'grey-300');

    }
  }
}

.p-togglebutton {
  height: initial;
  background: white;
  border-color: map.get($colors, 'grey-400');
  color: map.get($colors, 'grey-800');

  &.p-disabled {
    background: map.get($colors, 'grey-200');
    color: map.get($colors, 'grey-600');
    border-color: map.get($colors, 'grey-400');
    pointer-events: none;
  }

  &:hover:not(.p-disabled) {
    border-color: map.get($colors, 'grey-500');
  }

  &:focus:not(.p-disabled) {
    border-color: map.get($colors, 'grey-500');
  }

  &.p-highlight {
    background: map.get($colors, 'blue-100');
    border-color: map.get($colors, 'blue-500');
    color: map.get($colors, 'blue-700');

    &.p-disabled {
      background: map.get($colors, 'blue-100');
      color: map.get($colors, 'blue-300');
      border-color: map.get($colors, 'blue-200');
    }

    &:hover:not(.p-disabled) {
      border-color: map.get($colors, 'blue-600');
    }

    &:focus:not(.p-disabled) {
      border-color: map.get($colors, 'blue-500');
    }
  }
}


// PrimeNG listbox

/* Style for the PrimeNG Listbox component */
.p-listbox {
  background: #ffffff; /* White background similar to Material */
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; /* Material font */
}

.p-listbox .p-listbox-list {
  padding: 0; /* No padding for the list itself */
  margin: 0; /* No margin */
}

.p-listbox .p-checkbox {
  margin-right: 12px;
}

.p-listbox .p-listbox-item {
  padding: 12px 32px; /* Material-style spacing */
  height: 48px;
  border-bottom: 1px solid #e0e0e0; /* Divider between items */
  color: #424242; /* Default text color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s, box-shadow 0.2s; /* Smooth hover effect */
  font-size: 16px;
  line-height: 20px;
}

.p-listbox .p-listbox-item:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.p-listbox .p-listbox-item:hover {
  background: rgba(0, 0, 0, 0.04); /* Light hover background */
}

.p-listbox .p-listbox-item.p-highlight {
  background: #e3f2fd; /* Highlighted item color (Material blue 100) */
  color: #0d47a1; /* Material blue 900 for text */
}

/* Focus and selected states */
.p-listbox:focus-within {
  border-color: #1976d2; /* Material blue 700 on focus */
  box-shadow: 0px 0px 0px 2px rgba(25, 118, 210, 0.2); /* Focus outline */
}

.p-listbox .p-listbox-item:focus {
  outline: none; /* Remove default outline */
  background: rgba(25, 118, 210, 0.1); /* Focused item background */
}

.radiobutton-wrapper:has(input[type="radio"]:disabled) label{
  @apply text-pb-grey-500;
}
