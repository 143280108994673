.fak.fa-light-arrow-down-to-square-circle-info, .fa-kit.fa-light-arrow-down-to-square-circle-info { --fa: "\e03f"; --fa--fa: "\e03f\e03f"; }
.fak.fa-light-shield-halved-circle-check, .fa-kit.fa-light-shield-halved-circle-check { --fa: "\e03d"; --fa--fa: "\e03d\e03d"; }
.fak.fa-light-shield-keyhole-circle-check, .fa-kit.fa-light-shield-keyhole-circle-check { --fa: "\e03e"; --fa--fa: "\e03e\e03e"; }
.fak.fa-light-user-police-tie-circle-check, .fa-kit.fa-light-user-police-tie-circle-check { --fa: "\e034"; --fa--fa: "\e034\e034"; }
.fak.fa-light-user-police-tie-circle-minus, .fa-kit.fa-light-user-police-tie-circle-minus { --fa: "\e035"; --fa--fa: "\e035\e035"; }
.fak.fa-pb-angles-down-triple, .fa-kit.fa-pb-angles-down-triple { --fa: "\e006"; --fa--fa: "\e006\e006"; }
.fak.fa-pb-bitts, .fa-kit.fa-pb-bitts { --fa: "\e021"; --fa--fa: "\e021\e021"; }
.fak.fa-pb-calender-arrow-right, .fa-kit.fa-pb-calender-arrow-right { --fa: "\e03a"; --fa--fa: "\e03a\e03a"; }
.fak.fa-pb-cargo-crane, .fa-kit.fa-pb-cargo-crane { --fa: "\e007"; --fa--fa: "\e007\e007"; }
.fak.fa-pb-circle-arrow-down, .fa-kit.fa-pb-circle-arrow-down { --fa: "\e029"; --fa--fa: "\e029\e029"; }
.fak.fa-pb-circle-arrow-left, .fa-kit.fa-pb-circle-arrow-left { --fa: "\e026"; --fa--fa: "\e026\e026"; }
.fak.fa-pb-circle-arrow-right, .fa-kit.fa-pb-circle-arrow-right { --fa: "\e022"; --fa--fa: "\e022\e022"; }
.fak.fa-pb-circle-ban, .fa-kit.fa-pb-circle-ban { --fa: "\e02c"; --fa--fa: "\e02c\e02c"; }
.fak.fa-pb-circle-check-solid, .fa-kit.fa-pb-circle-check-solid { --fa: "\e008"; --fa--fa: "\e008\e008"; }
.fak.fa-pb-circle-comment, .fa-kit.fa-pb-circle-comment { --fa: "\e02a"; --fa--fa: "\e02a\e02a"; }
.fak.fa-pb-circle-dot, .fa-kit.fa-pb-circle-dot { --fa: "\e024"; --fa--fa: "\e024\e024"; }
.fak.fa-pb-circle-ellipsis-solid, .fa-kit.fa-pb-circle-ellipsis-solid { --fa: "\e009"; --fa--fa: "\e009\e009"; }
.fak.fa-pb-circle-exclamation-solid, .fa-kit.fa-pb-circle-exclamation-solid { --fa: "\e00a"; --fa--fa: "\e00a\e00a"; }
.fak.fa-pb-circle-info-solid, .fa-kit.fa-pb-circle-info-solid { --fa: "\e00b"; --fa--fa: "\e00b\e00b"; }
.fak.fa-pb-circle-location-arrow-solid, .fa-kit.fa-pb-circle-location-arrow-solid { --fa: "\e00c"; --fa--fa: "\e00c\e00c"; }
.fak.fa-pb-circle-pencil, .fa-kit.fa-pb-circle-pencil { --fa: "\e023"; --fa--fa: "\e023\e023"; }
.fak.fa-pb-circle-question-solid, .fa-kit.fa-pb-circle-question-solid { --fa: "\e00d"; --fa--fa: "\e00d\e00d"; }
.fak.fa-pb-circle-solid, .fa-kit.fa-pb-circle-solid { --fa: "\e025"; --fa--fa: "\e025\e025"; }
.fak.fa-pb-circle-xmark-solid, .fa-kit.fa-pb-circle-xmark-solid { --fa: "\e00e"; --fa--fa: "\e00e\e00e"; }
.fak.fa-pb-clock-nine-thick, .fa-kit.fa-pb-clock-nine-thick { --fa: "\e027"; --fa--fa: "\e027\e027"; }
.fak.fa-pb-clock-twelve-thick, .fa-kit.fa-pb-clock-twelve-thick { --fa: "\e028"; --fa--fa: "\e028\e028"; }
.fak.fa-pb-container-arrow-down, .fa-kit.fa-pb-container-arrow-down { --fa: "\e000"; --fa--fa: "\e000\e000"; }
.fak.fa-pb-container-arrow-up, .fa-kit.fa-pb-container-arrow-up { --fa: "\e001"; --fa--fa: "\e001\e001"; }
.fak.fa-pb-container-empty, .fa-kit.fa-pb-container-empty { --fa: "\e032"; --fa--fa: "\e032\e032"; }
.fak.fa-pb-container-flat, .fa-kit.fa-pb-container-flat { --fa: "\e039"; --fa--fa: "\e039\e039"; }
.fak.fa-pb-container-open-top, .fa-kit.fa-pb-container-open-top { --fa: "\e036"; --fa--fa: "\e036\e036"; }
.fak.fa-pb-container-refrigerated, .fa-kit.fa-pb-container-refrigerated { --fa: "\e038"; --fa--fa: "\e038\e038"; }
.fak.fa-pb-container-tank, .fa-kit.fa-pb-container-tank { --fa: "\e037"; --fa--fa: "\e037\e037"; }
.fak.fa-pb-customs, .fa-kit.fa-pb-customs { --fa: "\e002"; --fa--fa: "\e002\e002"; }
.fak.fa-pb-customs-accepted, .fa-kit.fa-pb-customs-accepted { --fa: "\e003"; --fa--fa: "\e003\e003"; }
.fak.fa-pb-customs-ban, .fa-kit.fa-pb-customs-ban { --fa: "\e031"; --fa--fa: "\e031\e031"; }
.fak.fa-pb-customs-denied, .fa-kit.fa-pb-customs-denied { --fa: "\e004"; --fa--fa: "\e004\e004"; }
.fak.fa-pb-customs-in-progress, .fa-kit.fa-pb-customs-in-progress { --fa: "\e005"; --fa--fa: "\e005\e005"; }
.fak.fa-pb-customs-shield, .fa-kit.fa-pb-customs-shield { --fa: "\e02f"; --fa--fa: "\e02f\e02f"; }
.fak.fa-pb-dangerdiamond-corrosive, .fa-kit.fa-pb-dangerdiamond-corrosive { --fa: "\e00f"; --fa--fa: "\e00f\e00f"; }
.fak.fa-pb-dangerdiamond-explosive, .fa-kit.fa-pb-dangerdiamond-explosive { --fa: "\e010"; --fa--fa: "\e010\e010"; }
.fak.fa-pb-dangerdiamond-flammable, .fa-kit.fa-pb-dangerdiamond-flammable { --fa: "\e011"; --fa--fa: "\e011\e011"; }
.fak.fa-pb-dangerdiamond-general, .fa-kit.fa-pb-dangerdiamond-general { --fa: "\e012"; --fa--fa: "\e012\e012"; }
.fak.fa-pb-dangerdiamond-infectious, .fa-kit.fa-pb-dangerdiamond-infectious { --fa: "\e013"; --fa--fa: "\e013\e013"; }
.fak.fa-pb-dangerdiamond-miscellaneous, .fa-kit.fa-pb-dangerdiamond-miscellaneous { --fa: "\e014"; --fa--fa: "\e014\e014"; }
.fak.fa-pb-dangerdiamond-non-flammable, .fa-kit.fa-pb-dangerdiamond-non-flammable { --fa: "\e015"; --fa--fa: "\e015\e015"; }
.fak.fa-pb-dangerdiamond-oxidizing, .fa-kit.fa-pb-dangerdiamond-oxidizing { --fa: "\e016"; --fa--fa: "\e016\e016"; }
.fak.fa-pb-dangerdiamond-radioactive, .fa-kit.fa-pb-dangerdiamond-radioactive { --fa: "\e017"; --fa--fa: "\e017\e017"; }
.fak.fa-pb-dangerdiamond-toxic, .fa-kit.fa-pb-dangerdiamond-toxic { --fa: "\e018"; --fa--fa: "\e018\e018"; }
.fak.fa-pb-ellipsis-h-large, .fa-kit.fa-pb-ellipsis-h-large { --fa: "\e019"; --fa--fa: "\e019\e019"; }
.fak.fa-pb-ellipsis-v-large, .fa-kit.fa-pb-ellipsis-v-large { --fa: "\e01a"; --fa--fa: "\e01a\e01a"; }
.fak.fa-pb-file-line, .fa-kit.fa-pb-file-line { --fa: "\e02b"; --fa--fa: "\e02b\e02b"; }
.fak.fa-pb-file-lines-slash, .fa-kit.fa-pb-file-lines-slash { --fa: "\e033"; --fa--fa: "\e033\e033"; }
.fak.fa-pb-gate-closed, .fa-kit.fa-pb-gate-closed { --fa: "\e01c"; --fa--fa: "\e01c\e01c"; }
.fak.fa-pb-gate-open, .fa-kit.fa-pb-gate-open { --fa: "\e01b"; --fa--fa: "\e01b\e01b"; }
.fak.fa-pb-grip-horizontal, .fa-kit.fa-pb-grip-horizontal { --fa: "\e01d"; --fa--fa: "\e01d\e01d"; }
.fak.fa-pb-inbox-queue, .fa-kit.fa-pb-inbox-queue { --fa: "\e01e"; --fa--fa: "\e01e\e01e"; }
.fak.fa-pb-ship-to-ship, .fa-kit.fa-pb-ship-to-ship { --fa: "\e01f"; --fa--fa: "\e01f\e01f"; }
.fak.fa-pb-tower-control-ban, .fa-kit.fa-pb-tower-control-ban { --fa: "\e030"; --fa--fa: "\e030\e030"; }
.fak.fa-pb-transit, .fa-kit.fa-pb-transit { --fa: "\e020"; --fa--fa: "\e020\e020"; }
.fak.fa-pb-user-arrow-right-left, .fa-kit.fa-pb-user-arrow-right-left { --fa: "\e02e"; --fa--fa: "\e02e\e02e"; }

.fak, .fa-kit {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: var(--fa-display, inline-block);
  font-family: "Font Awesome Kit";
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
}

.fak::before, .fa-kit::before {
  content: var(--fa);
}

@font-face {
  font-family: "Font Awesome Kit";
  font-style: normal;
  font-display: block;
  src: url("../webfonts/custom-icons.woff2") format("woff2"), url("../webfonts/custom-icons.ttf") format("truetype");
}
